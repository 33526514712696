import React from "react";

export function GoogleIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10242_18570)">
        <path
          d="M10.2042 8.18188V12.0546H15.6959C15.4547 13.3001 14.7311 14.3547 13.6458 15.0638L16.9574 17.582C18.887 15.8366 20.0001 13.2729 20.0001 10.2275C20.0001 9.51837 19.9352 8.83648 19.8146 8.18199L10.2042 8.18188Z"
          fill="#4285F4"
        />
        <path
          d="M4.48542 11.9034L3.73851 12.4638L1.09467 14.4819C2.77371 17.7455 6.21503 20.0001 10.2039 20.0001C12.9589 20.0001 15.2688 19.1092 16.9571 17.582L13.6454 15.0638C12.7363 15.6638 11.5768 16.0274 10.2039 16.0274C7.55083 16.0274 5.29671 14.2729 4.48959 11.9092L4.48542 11.9034Z"
          fill="#34A853"
        />
        <path
          d="M1.09467 5.51819C0.39897 6.8636 0.00012207 8.38181 0.00012207 9.99997C0.00012207 11.6181 0.39897 13.1363 1.09467 14.4818C1.09467 14.4908 4.48992 11.8999 4.48992 11.8999C4.28584 11.2999 4.16521 10.6636 4.16521 9.99987C4.16521 9.33614 4.28584 8.69981 4.48992 8.09981L1.09467 5.51819Z"
          fill="#FBBC05"
        />
        <path
          d="M10.2041 3.98184C11.7069 3.98184 13.0427 4.49092 14.1095 5.47275L17.0316 2.60914C15.2597 0.990976 12.9593 0 10.2041 0C6.21524 0 2.77371 2.24546 1.09467 5.51822L4.48981 8.10005C5.29682 5.73638 7.55104 3.98184 10.2041 3.98184Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_10242_18570">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
