import { GoogleIcon } from "~/components/icons";

import { jwtDecode } from "jwt-decode";

import type {
  CredentialResponse,
  PromptMomentNotification,
  accounts,
} from "google-one-tap";

import { type Dispatch, type SetStateAction } from "react";
import { SignUpSteps } from "../signUpForm";
import type { GoogleAuthProps, GoogleUserData } from "./entity";

declare global {
  interface Window {
    google: { accounts: accounts };
  }
}

type GoogleAuthComponentProps = Partial<GoogleAuthProps> & {
  setStep?: Dispatch<SetStateAction<SignUpSteps>>;
  setToken?: Dispatch<SetStateAction<string>>;
  signUp?: boolean;
  clientId: string;
  handleSignIn?: (data: GoogleUserData) => void;
};
export function GoogleAuth({
  signUp = true,
  handleSignIn,
  setStep,
  clientId,
  setUserData,
  setToken,
}: GoogleAuthComponentProps) {
  const text = signUp ? "Sign up with Google" : "Sign in with Google";

  const googleOneTap = () => {
    const { google } = window;
    if (google) {
      google.accounts.id.initialize({
        client_id: clientId,
        callback: async (response: CredentialResponse) => {
          const token = response.credential;

          await registerUser(token);
        },
      });

      return google.accounts.id.prompt(
        (notification: PromptMomentNotification) => {
          console.log("Notification ::", notification);
          if (notification.isNotDisplayed()) {
            console.log(
              "getNotDisplayedReason ::",
              notification.getNotDisplayedReason(),
            );
          } else if (notification.isSkippedMoment()) {
            console.log(
              "getSkippedReason  ::",
              notification.getSkippedReason(),
            );
          } else if (notification.isDismissedMoment()) {
            console.log(
              "getDismissedReason ::",
              notification.getDismissedReason(),
            );
          }
        },
      );
    }
  };

  async function registerUser(token: string) {
    const decodedToken = jwtDecode<{
      email: string;
      given_name: string;
      family_name: string;
    }>(token);

    if (signUp) {
      setStep?.(SignUpSteps.G_AUTH_USER_INFO);
      setToken?.(token);
      setUserData?.((prev) => ({
        ...prev,
        email: decodedToken.email,
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
        token,
      }));
    }

    if (!signUp) {
      await handleSignIn?.({
        email: decodedToken.email,
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
        token,
      });
    }
  }

  return (
    <>
      <script src="https://accounts.google.com/gsi/client" />
      <div
        onClick={() => googleOneTap()}
        className="flex h-[50px] w-full cursor-pointer rounded-lg border border-gray-500 shadow-md"
      >
        <div className="flex w-1/6 items-center justify-center p-2">
          <GoogleIcon />
        </div>

        <div className="flex w-5/6 items-center rounded-br-lg rounded-tr-lg bg-white text-sm font-bold">
          <span className="ml-4">{text}</span>
        </div>
      </div>
    </>
  );
}
