import React from "react";
import { Input } from "~/components/ui/forms/inputs";
import type { FieldMetadata } from "@conform-to/react";

export function EmailStep({
  fields,
  errors,
}: {
  fields: Record<string, FieldMetadata>;
  errors: Record<string, string[]>;
}) {
  return (
    <div>
      <Input
        id={fields.email.name}
        name={fields.email.name}
        type="email"
        autoComplete="email"
        defaultValue=""
        placeholder="you@yourbusiness.com"
        aria-invalid={
          [...(fields.email.errors || []), ...(errors.email || [])].filter(
            Boolean,
          ).length > 0
        }
        label="Email *"
        error={[...(fields.email.errors || []), ...(errors.email || [])]
          .filter(Boolean)
          .join(", ")}
      />
    </div>
  );
}
